<script>
import Layout from "@/router/layouts/courses";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Courses': 'Cursos',
      'Buy': 'Comprar',
    },
    es: {
      'Courses': 'Cursos',
      'Buy': 'Comprar',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      courses: {
        loading: true,
        errored: false,
        empty: false,
        list: null,
      },
    };
  },
  methods: {
    getCourses() {
      this.courses.loading = true
      this.courses.list = null

      api
        .get('course')
        .then(response => {
          if (response.data.status=='success') {
            this.courses.list = response.data.list
            this.courses.loading = false
          } else {
            this.courses.list = null
          }
        })
        .catch(error => {
          this.courses.errored = error
          this.courses.loading = false
        })
        .finally(() => {
          this.courses.loading = false
        })
    }
  },
  mounted() {
    this.getCourses()
  },
  computed: {
    monitorWidth: function() {
      var width = screen.width;
      return width;
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18 text-white">Nossos Cursos</h4>
        </div>
      </div>
    </div>
    <div v-if="courses.loading" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
    </div>
    <div v-else-if="!courses.list || courses.list && courses.list.length == 0">
      <div class="text-center py-5 text-white">
        <i class="far fa-sad-cry font-size-24"></i><br>
        ainda não temos nenhum curso disponível
      </div>
    </div>
    <div v-else class="courses-list row">
      <div class="courses-item col-6 col-md-4 col-lg-3 col-xl-3 mb-3" v-for="(course,id) in courses.list" :key="id">
        <router-link tag="a" :to="'/courses/' + course.slug">
          <template v-if="monitorWidth < 600">
            <img :src="course.cover.mobile">
          </template>
          <template v-else>
            <img :src="course.cover.desktop">
          </template>
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.course .card.bg-soft-dark {
  background-color: #e6e6e6 !important;
}
.course:hover .card.bg-soft-dark {
  background-color: #ddd !important;
}
</style>